<template>
  <div>
    <v-container>
      <v-row class='mt-10 flex-column align-center'>
        <div>
          <v-col cols='12' sm='6' md='12'>
            <v-img
              max-height='270'
              max-width='270'
              src='../assets/Mendoza-Fiduciaria-logo.png'
            ></v-img>
          </v-col>
        </div>
      </v-row>
      <v-alert type='success' dark icon='mdi-alert-circle-outline'>
        Verificamos tu cuenta correctamente, ahora puedes ...
      </v-alert>
      <v-btn block color='primary' @click='redirectLogin'>
        Ingresar
      </v-btn>
    </v-container>
  </div>
</template>
<script>
export default {
  methods: {
    redirectLogin() {
      localStorage.setItem('emailStatus', 'confirmed');
      const querystring = window.location.search;
      const params = new URLSearchParams(querystring);
      const email = params.get('email');
      localStorage.setItem('email', email);
      this.$router.push('/');
    },
  },
};
</script>
